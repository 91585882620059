import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClient from '../HttpClient';
import HttpClientV2 from '../HttpClientV2';
import ItemState from '../constants/ItemState';
import Operation from '../constants/Operation';
import RegexTypes from '../constants/RegexTypes';

const className = 'Offer';
const initialOptions = {
  sortBy: 'createdAt',
  descending: true,
  page: 0,
  rowsPerPage: 20,
};

export default function Offers(currency) {
  const state = reactive({
    trackingLink: false,
    symbol: computed(() => (currency == 'USD' ? '$' : '€')),
    operation: Operation.NONE,
    items: [],
    total: 0,
    appliedFilters: {},
    selectedFilters: {},
    options: initialOptions,
    item: null,
    application: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
    preSelectItemPage: {},
    isTable: true,
    rowsNumber: 20,
    rowsNumberList: 8,
  });

  const actions = {
    setPreSelectItemPage(value) {
      _.set(state, 'preSelectItemPage', value);
    },
    async applyFilters() {
      await this.setSelectedToAppliedFilters();
      _.set(state, 'options', {
        sortBy: state.options.sortBy,
        descending: null,
        page: 0,
        rowsPerPage: state.isTable ? state.rowsNumber : state.rowsNumberList,
      });
      await this.loadItems();
    },
    async setSelectedToAppliedFilters() {
      _.set(state, 'appliedFilters', _.cloneDeep(state.selectedFilters));
    },
    async setAppliedToSelectedFilters() {
      _.set(state, 'selectedFilters', _.cloneDeep(state.appliedFilters));
    },
    setSelectedFilter(key, value) {
      if (key === '_id' && !RegexTypes.ValidMongoDBObjectId.test(value)) {
        value = null;
      }
      if (_.isNil(value) || _.isEmpty(value)) {
        _.unset(state, `selectedFilters.${key}`);
      } else {
        _.set(state, `selectedFilters.${key}`, value);
      }
    },
    async resetFilters() {
      state.selectedFilters = {};
    },
    async removeFilter({ key }) {
      delete state['selectedFilters'][key];
    },
    async applyOptions({ value }) {
      if (_.isNil(value.sortBy)) {
        value.sortBy = state.options.sortBy;
      }
      if (_.isNil(value.descending) || value.descending === undefined) {
        value.descending = state.options.descending;
      }
      if (state.isTable) {
        this.setRowsNumber(state.rowsNumber);
        this.updateAttribute({ key: 'state.options.rowsPerPage', value: state.rowsNumber });
        this.updateAttribute({ key: 'rowsNumber', value: value.rowsPerPage });
      } else {
        this.setRowsNumber(state.rowsNumberList);
        this.updateAttribute({
          key: 'state.options.rowsPerPage',
          value: state.rowsNumberList,
        });
        this.updateAttribute({ key: 'rowsNumberList', value: value.rowsPerPage });
      }
      _.set(state, 'options', value);
      await this.loadItems();
    },
    async loadItems() {
      if (_.isEqual(_.get(state, 'operation'), Operation.NONE)) {
        try {
          _.set(state, 'operation', Operation.IS_CREATING);
          const filters = Object.keys(state.appliedFilters).length > 0 ? state.appliedFilters : {};
          const options = _.get(state, 'options');
          const payload = {
            page: options.page || 0,
            limit: options.rowsPerPage || 20,
            descending: options.descending === true ? true : false,
            sortBy: options.sortBy || 'createdAt',
            ...filters,
          };
          for (const filter of Object.keys(payload)) {
            _.isArray(payload[filter]) && (payload[filter] = payload[filter].join(','));
          }
          const result = await HttpClientV2.callFunctionV2('GET', 'offer', payload);
          await Promise.all(
            result.resultSet.map(async (offer) => {
              offer.payout.costs[0].value = offer.offerPayout;
            })
          );
          _.set(state, 'items', result.resultSet);
          _.set(state, 'total', result.total);
        } catch (error) {
          _.set(state, 'items', []);
          _.set(state, 'total', 0);
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      }
    },
    async loadAndSelectItem(_offer) {
      try {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
        _.set(state, 'operation', Operation.IS_GETTING);
        const result = await HttpClientV2.callFunctionV2('GET', 'offer', { _id: _offer });
        if (result.total == 1) {
          await this.selectItem({ value: result.resultSet[0] });
        } else {
          _.set(state, 'operation', Operation.NONE);
          await this.loadItems({});
        }
      } catch (error) {
        _.set(state, 'operation', Operation.NONE);
        await this.loadItems({});
      }
    },
    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
      await this.selectApplication({});
    },
    async selectApplication() {
      if (!state.item) return;
      _.set(state, 'application', null);
      const params = { _id: state.item._id };
      const application = await HttpClientV2.callFunctionV2(
        'GET',
        'offer/applicationValidation',
        params
      );
      _.set(state, 'application', application);
      if (
        application &&
        application.applicationStatus === 'running' &&
        application.overwritePayout
      ) {
        _.set(state, 'item.payout.costs[0].value', application.payout);
      }
    },
    async runApplication({ survey, type, status }) {
      if (!state.item) return;
      _.set(state, 'application', null);
      const payload = {
        _id: state.item._id,
        survey,
        type,
      };
      let application = await await HttpClientV2.callFunctionV2(
        'POST',
        'offer/applicationRun',
        payload
      );
      _.set(state, 'application', application.data);
      if (status === 'Apply to Run') {
        _.set(state, 'item.validatedStatus', 'Applied');
      }
    },
    async unselectItem() {
      _.set(state, 'application', null);
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
      _.set(state, 'operation', Operation.NONE);
      _.set(state, 'trackingLink', false);
      await this.loadItems();
    },
    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },
    async editItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if ([ItemState.PERSISTENT, ItemState.TRANSIENT, ItemState.DETACHED].includes(itemState)) {
        _.set(state, 'isEditing', true);
      } else {
        throw new Error('The item must be in the PERSISTENT, TRANSIENT or DETACHED state to edit.');
      }
    },
    async saveItem() {
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.TRANSIENT) {
        try {
          _.set(state, 'operation', Operation.IS_CREATING);
          let result = await HttpClient.runProcess(className, 'create_one', {
            payload: _.get(state, 'item'),
          });
          _.set(state, 'item', result);
          _.set(state, 'itemState', ItemState.PERSISTENT);
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else if (itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_UPDATING);
          let result = await HttpClient.runProcess(className, 'update_one', {
            payload: _.get(state, 'item'),
          });
          _.set(state, 'item', result);
          _.set(state, 'itemState', ItemState.PERSISTENT);
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the TRANSIENT or DETACHED state to save.');
      }
    },
    async deleteItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.PERSISTENT || itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_DELETING);
          await HttpClient.runProcess(className, 'delete_one', { payload: _.get(state, 'item') });
          await this.unselectItem({});
          await this.loadItems({});
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the PERSISTENT or DETACHED state to delete.');
      }
    },
    async updateAttribute({ key, value }) {
      _.set(state, `${key}`, value);
    },
    async updateItemAttribute({ key, value }) {
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },
    async markAsModified() {
      let itemState = _.get(state, 'itemState');
      if (itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
    setOffersView(value) {
      _.set(state, 'isTable', value);
    },
    setRowsNumber(value) {
      if (state.isTable) {
        _.set(state, 'rowsNumber', value);
        state.options.rowsPerPage = state.rowsNumber;
        this.updateAttribute({ key: 'state.options.rowsPerPage', value: state.rowsNumber });
      } else {
        _.set(state, 'rowsNumberList', value);
        state.options.rowsPerPage = state.rowsNumberList;
        this.updateAttribute({ key: 'state.options.rowsPerPage', value: state.rowsNumberList });
      }
    },
    async updateExtraFields(payload) {
      try {
        await HttpClient.runProcess('Application', 'update_One', { payload: payload });
      } catch (error) {
        throw new Error('Unable to update aff_sub parameters.');
      }
    },
    async resetOptions() {
      state.options = {
        ...initialOptions,
        rowsPerPage: state.isTable ? state.rowsNumber : state.rowsNumberList,
      };
    },
    async resetOffers() {
      state.items = [];
    },
  };
  return { state, actions };
}
