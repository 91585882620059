import {
  mdiMathCompass,
  mdiTestTube,
  mdiPencilRuler,
  mdiCheckAll,
  mdiWrench,
  mdiArrowExpandUp,
  mdiArrowExpandDown,
  mdiArrowExpandRight,
  mdiArrowExpandLeft,
  mdiArrowCollapseUp,
  mdiArrowCollapseDown,
  mdiArrowCollapseRight,
  mdiArrowCollapseLeft,
  mdiCurrencyUsd,
  mdiCursorPointer,
  mdiGestureTap,
  mdiTextShort,
  mdiIdentifier,
  mdiAt,
  mdiCalendarCheck,
  mdiCalendarEdit,
  mdiCheckboxBlankOff,
  mdiCheckboxBlankOffOutline,
  mdiCheckboxMarked,
  mdiCheckboxMarkedOutline,
  mdiCircleOffOutline,
  mdiMinusCircleOff,
  mdiCheckboxMarkedCircleOutline,
  mdiCheckboxMarkedCircle,
  mdiFormTextbox,
  mdiKeyChainVariant,
  mdiStateMachine,
  mdiViewSplitVertical,
  mdiClose,
  mdiGestureSwipeRight,
  mdiUndo,
  mdiCancel,
  mdiContentSave,
  mdiContentSaveAlert,
  mdiTableSearch,
  mdiMagnifyMinus,
  mdiImage,
  mdiContentDuplicate,
  mdiTransfer,
  mdiContentCopy,
  mdiCheck,
} from '@mdi/js';

export default {
  menu: 'apps',
  menuDesktopOpened: mdiViewSplitVertical,
  menuDesktopClosed: mdiClose,
  menuMobile: 'apps',
  closeRight: mdiGestureSwipeRight,
  copyContent: mdiContentCopy,
  // Sign
  singIn: 'play_circle_outline',
  singOut: 'power_settings_new',
  dashboard: 'dashboard',
  reports: 'mdi-timeline-text-outline',
  conversions: 'mdi-timeline-check-outline',
  commands: 'mdi-console-network-outline',
  report: 'mdi-file-table-box-multiple-outline',
  historical: 'history',
  comparative: 'details',
  offers: 'mdi-briefcase-clock-outline',
  offer: 'mdi-briefcase-clock-outline',
  _offer: 'mdi-briefcase-clock-outline',
  network: 'mdi-table-network',
  _network: 'mdi-table-network',
  users: 'supervisor_account',
  user: 'supervisor_account',
  _user: 'supervisor_account',
  roles: 'security',
  role: 'security',
  _role: 'security',
  advertisers: 'mdi-account-tie-voice',
  advertiser: 'mdi-account-tie-voice',
  _advertiser: 'mdi-account-tie-voice',
  affiliates: 'mdi-account-network',
  affiliate: 'mdi-account-network',
  _affiliate: 'mdi-account-network',
  affiliateGroups: 'mdi-check-network-outline',
  _affiliateGroups: 'mdi-check-network-outline',
  affiliateGroup: 'mdi-check-network-outline',
  _affiliateGroup: 'mdi-check-network-outline',
  categories: 'mdi-group',
  _categories: 'mdi-group',
  category: 'mdi-group',
  _category: 'mdi-group',
  currency: 'mdi-currency-usd',
  affiliateTiers: 'mdi-folder-network-outline',
  payables: 'mdi-currency-eur',
  _payables: 'mdi-currency-eur',
  payable: 'mdi-currency-eur',
  _payable: 'mdi-currency-eur',
  pdf: 'mdi-file-pdf',
  payableOptions: {
    paid: 'mdi-currency-eur',
    unpaid: 'mdi-currency-eur-off',
    paymentnote: 'mdi-receipt',
    invoice: 'mdi-card-text-outline',
    manual: 'mdi-hand',
    periodic: 'mdi-calendar-clock',
    week: 'mdi-calendar-week',
    month: 'mdi-calendar-month',
  },
  conversion: {
    unbillable: 'mdi-currency-eur-off',
  },
  calendar: 'mdi-calendar',
  settings: 'tune',
  details: 'subtitles',
  chartBar: 'bar_chart',
  chartBubble: 'bubble_chart',
  chartLine: 'show_chart',
  chartMultiline: 'multiline_chart',
  chartPie: 'pie_chart',
  chartTable: 'table_chart',
  sentimentVerySatisfied: 'sentiment_satisfied_alt',
  sentimentSatisfied: 'sentiment_satisfied',
  sentimentDissatisfied: 'sentiment_dissatisfied',
  sentimentVeryDissatisfied: 'sentiment_very_dissatisfied',
  switch: {
    star: {
      on: 'star',
      off: 'star_outline',
    },
    check: {
      on: mdiCheckboxMarked,
      onDisabled: mdiCheckboxMarkedOutline,
      off: mdiCheckboxBlankOff,
      offDisabled: mdiCheckboxBlankOffOutline,
    },
    radio: {
      on: 'radio_button_checked',
      off: 'radio_button_unchecked',
    },
    circle: {
      on: mdiCheckboxMarkedCircle,
      onDisabled: mdiCheckboxMarkedCircleOutline,
      off: mdiMinusCircleOff,
      offDisabled: mdiCircleOffOutline,
    },
    flag: {
      on: 'flag',
      off: 'outlined_flag',
    },
    heart: {
      on: 'favorite',
      off: 'favorite_border',
    },
    lock: {
      on: 'lock',
      off: 'lock_open',
    },
    flash: {
      on: 'flash_on',
      off: 'flash_off',
    },
    event: {
      on: 'event_available',
      off: 'event_busy',
    },
    hand: {
      on: 'thumb_up',
      off: 'thumb_down',
    },
    eye: {
      on: 'visibility',
      off: 'visibility_off',
    },
    filter: {
      on: mdiTableSearch,
      off: mdiMagnifyMinus,
    },
  },
  image: mdiImage,
  thumbnail: mdiImage,
  color: 'color_lens',
  comment: 'comment',
  change: 'repeat',
  boolean: 'check_circle_outline',
  password: mdiKeyChainVariant,
  state: mdiStateMachine,
  contacts: 'contacts',
  contactMail: 'contact_mail',
  contactPhone: 'contact_phone',
  language: 'language',
  translate: 'translate',
  date: 'calendar_today',
  dateToday: 'today',
  dateEvent: 'event',
  dateEventAvailable: 'event_available',
  dateEventBusy: 'event_busy',
  dateEventNote: 'event_note',
  time: 'schedule',
  timeRestore: 'restore',
  timeUpdate: 'update',
  alarm: 'alarm',
  alarmOn: 'alarm_on',
  alarmOff: 'alarm_off',
  alarmAdd: 'alarm_add',
  timeline: 'timeline',
  timeprogress: 'update',
  trending: 'trending_flat',
  trendingUp: 'trending_up',
  trendingDown: 'trending_down',
  zoomIn: 'zoom_in',
  zoomOut: 'zoom_out',
  expandLess: 'expand_less',
  expandMore: 'expand_more',
  upload: 'cloud_upload',
  uploadDone: 'cloud_done',
  download: 'cloud_download',
  info: 'info',
  help: 'help',
  edit: 'mdi-fountain-pen',
  editStyle: mdiPencilRuler,
  add: 'add',
  remove: 'remove',
  increase: 'add',
  decrease: 'remove',
  save: mdiCheckAll,
  send: 'send',
  delete: 'delete',
  cancel: mdiCancel,
  close: 'mdi-close',
  confirm: mdiContentSaveAlert,
  saveDisk: mdiContentSave,
  search: 'search',
  clear: 'clear',
  select: 'touch_app',
  duplicate: mdiContentDuplicate,
  back: 'keyboard_return',
  goBack: mdiUndo,
  seeMore: 'subdirectory_arrow_right',
  selected: mdiGestureTap,
  unselected: mdiCursorPointer,
  test: mdiTestTube,
  design: mdiPencilRuler,
  logic: 'functions',
  construction: mdiWrench,
  field: mdiTextShort,
  _id: mdiIdentifier,
  email: mdiAt,
  text: mdiFormTextbox,
  createdAt: mdiCalendarCheck,
  updatedAt: mdiCalendarEdit,
  usd: mdiCurrencyUsd,
  event: mdiCalendarCheck,
  theme: {
    elevation: 'flip_to_front',
    margin: 'border_outer',
    marginTop: mdiArrowExpandUp,
    marginRight: mdiArrowExpandRight,
    marginBottom: mdiArrowExpandDown,
    marginLeft: mdiArrowExpandLeft,
    paddingTop: mdiArrowCollapseUp,
    paddingRight: mdiArrowCollapseRight,
    paddingBottom: mdiArrowCollapseDown,
    paddingLeft: mdiArrowCollapseLeft,
    borderWidth: mdiMathCompass,
    borderStyle: mdiPencilRuler,
    borderRadius: 'rounded_corner',
    textAlign: {
      left: 'format_align_left',
      center: 'format_align_center',
      right: 'format_align_right',
      justify: 'format_align_justify',
    },
    textDecoration: 'format_strikethrough',
    textTransform: 'text_rotation_none',
    fontStyle: mdiPencilRuler,
    fontWeight: 'format_bold',
    fontSize: 'format_size',
  },
  device: {
    box: 'fullscreen',
    laptop: 'laptop_mac',
    tablet: 'tablet_mac',
    phone: 'phone_iphone',
  },
  paymentMethod: {
    WIRE_TRANSFER: mdiTransfer,
    PAYPAL: 'mdi-paypal',
    PAYONEER: 'mdi-paypal',
    CRYPTOCURRENCY: 'mdi-paypal',
  },
  twoFA: 'mdi-two-factor-authentication',
  alertCircle: 'mdi-alert-circle',
  checkCircle: 'mdi-check-circle',
  checkTick: mdiCheck,
};
