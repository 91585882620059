import _ from 'lodash';
import { reactive, computed } from '@vue/composition-api';
import HttpClient from '../HttpClient';
import HttpClientV2 from '../HttpClientV2';
import ItemState from '../constants/ItemState';
import Operation from '../constants/Operation';
import RegexTypes from '@/store/constants/RegexTypes';

const className = 'Smartlink';

export default function Smartlinks() {
  const state = reactive({
    operation: Operation.NONE,
    items: [],
    total: 0,
    appliedFilters: {},
    selectedFilters: {},
    options: {},
    item: null,
    application: null,
    itemState: ItemState.NOT_SELECTED,
    isEditing: false,
    isSelected: computed(() => state.itemState != ItemState.NOT_SELECTED),
    isModified: computed(() => [ItemState.DETACHED, ItemState.TRANSIENT].includes(state.itemState)),
    loading: computed(() => state.operation != Operation.NONE),
    rowsNumberList: 8,
  });

  const actions = {
    async applyFilters() {
      _.set(state, 'options.page', 0);
      await this.setSelectedToAppliedFilters();
      if (_.isEqual(_.get(state, 'operation'), Operation.IS_GETTING)) {
        setTimeout(async () => {
          await this.loadItems();
        }, 500);
      } else {
        await this.loadItems();
      }
    },
    async setSelectedToAppliedFilters() {
      _.set(state, 'appliedFilters', _.cloneDeep(state.selectedFilters));
    },
    async setAppliedToSelectedFilters() {
      _.set(state, 'selectedFilters', _.cloneDeep(state.appliedFilters));
    },
    setSelectedFilter(key, value) {
      if (key === '_id' && !RegexTypes.ValidMongoDBObjectId.test(value)) {
        value = null;
      }
      if (_.isNil(value) || _.isEmpty(value)) {
        _.unset(state, `selectedFilters.${key}`);
      } else {
        _.set(state, `selectedFilters.${key}`, value);
      }
    },
    async resetFilters() {
      state.appliedFilters = {};
    },
    async removeFilter({ key }) {
      delete state['selectedFilters'][key];
    },
    async applyOptions({ value }) {
      _.set(state, 'options', value);
      await this.loadItems();
    },
    async loadItems() {
      if (_.isEqual(_.get(state, 'operation'), Operation.NONE)) {
        try {
          _.set(state, 'operation', Operation.IS_GETTING);
          let filters = Object.keys(state.appliedFilters).length > 0 ? state.appliedFilters : {};
          let options = _.get(state, 'options');
          const result = await HttpClientV2.callFunctionV2('GET', 'Smartlink', {
            ...filters,
            ...options,
            page: options.page || 0,
            limit: options.rowsPerPage || 8,
          });
          _.set(state, 'items', result.resultSet);
          _.set(state, 'total', result.total);
        } catch (error) {
          _.set(state, 'items', []);
          _.set(state, 'total', 0);
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      }
    },
    async loadAndSelectItem(_offer) {
      try {
        _.set(state, 'items', []);
        _.set(state, 'total', 0);
        _.set(state, 'operation', Operation.IS_GETTING);
        let result = await HttpClient.runProcess(className, 'find_many', {
          filters: { _id: _offer },
        });
        if (result.total == 1) {
          await this.selectItem({ value: result.resultSet[0] });
        } else {
          _.set(state, 'operation', Operation.NONE);
          await this.loadItems({});
        }
      } catch (error) {
        _.set(state, 'operation', Operation.NONE);
        await this.loadItems({});
      }
    },
    async selectItem({ value }) {
      _.set(state, 'item', value);
      _.set(state, 'itemState', ItemState.PERSISTENT);
      await this.selectApplication({});
    },
    async selectApplication() {
      if (!state.item) return;
      _.set(state, 'application', null);
      let application = await HttpClientV2.callFunctionV2('GET', 'Smartlink/validation', {
        _id: state.item._id,
      });
      _.set(state, 'application', application);
    },
    async runApplication({ survey, type }) {
      if (!state.item) return;
      _.set(state, 'application', null);
      let application = await HttpClientV2.callFunctionV2(
        'POST',
        'SmartlinkPromotion/applyApplication',
        {
          _id: state.item._id,
          survey,
          type,
        }
      );
      _.set(state, 'application', application.data);
    },
    async unselectItem() {
      _.set(state, 'application', null);
      _.set(state, 'item', null);
      _.set(state, 'isEditing', false);
      _.set(state, 'itemState', ItemState.NOT_SELECTED);
      _.set(state, 'operation', Operation.NONE);
      await this.loadItems();
    },
    async newItem(item = {}) {
      _.set(state, 'item', item);
      _.set(state, 'isEditing', true);
      _.set(state, 'itemState', ItemState.TRANSIENT);
    },
    async editItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if ([ItemState.PERSISTENT, ItemState.TRANSIENT, ItemState.DETACHED].includes(itemState)) {
        _.set(state, 'isEditing', true);
      } else {
        throw new Error('The item must be in the PERSISTENT, TRANSIENT or DETACHED state to edit.');
      }
    },
    async saveItem() {
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.TRANSIENT) {
        try {
          _.set(state, 'operation', Operation.IS_CREATING);
          let result = await HttpClient.runProcess(className, 'create_one', {
            payload: _.get(state, 'item'),
          });
          _.set(state, 'item', result);
          _.set(state, 'itemState', ItemState.PERSISTENT);
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else if (itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_UPDATING);
          let result = await HttpClient.runProcess(className, 'update_one', {
            payload: _.get(state, 'item'),
          });
          _.set(state, 'item', result);
          _.set(state, 'itemState', ItemState.PERSISTENT);
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the TRANSIENT or DETACHED state to save.');
      }
    },
    async deleteItem({ value }) {
      if (value) {
        await this.selectItem({ value });
      }
      let itemState = _.get(state, 'itemState');
      if (itemState == ItemState.PERSISTENT || itemState == ItemState.DETACHED) {
        try {
          _.set(state, 'operation', Operation.IS_DELETING);
          await HttpClient.runProcess(className, 'delete_one', { payload: _.get(state, 'item') });
          await this.unselectItem({});
          await this.loadItems({});
        } catch (error) {
          //
        } finally {
          _.set(state, 'operation', Operation.NONE);
        }
      } else {
        throw new Error('The item must be in the PERSISTENT or DETACHED state to delete.');
      }
    },
    async updateAttribute({ key, value }) {
      _.set(state, `${key}`, value);
    },
    async updateItemAttribute({ key, value }) {
      _.set(state, `item.${key}`, value);
      await this.markAsModified({});
    },
    async markAsModified() {
      let itemState = _.get(state, 'itemState');
      if (itemState != ItemState.TRANSIENT) _.set(state, 'itemState', ItemState.DETACHED);
    },
    async updateExtraFields(payload) {
      try {
        await HttpClient.runProcess(className, 'update_One', { payload: payload });
      } catch (error) {
        throw new Error('Unable to update aff_sub parameters.');
      }
    },
  };
  return { state, actions };
}
